import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className="mt-auto text-white-50" style={{ userSelect: "auto" }}>
        <p style={{ userSelect: "auto" }}>
          Polarize Ltd
        </p>
      </footer>
    );
  }
}

export default Footer;
